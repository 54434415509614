import { Box, Button, CircularProgress, IconButton, ListItem, ListItemText, MenuItem, Select, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../main/navbar/Navbar";
import AddTooltip from "../main/addTooltip/AddTooltip";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import '@inovua/reactdatagrid-enterprise/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import {
  CellRenderObject
} from '@inovua/reactdatagrid-community/Layout/ColumnLayout/Cell/CellProps';
import { TypeComputedProps, TypeEditInfo } from '@inovua/reactdatagrid-community/types';
import PopUp from "../main/popUp/popUp";
import { postAgOrder, postCliOrder } from "../services/orderService";
import PopUpItems from "../main/popUp/popUpItems";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PPNavigation from "../main/ppNavigation/PPNavigation";
import bgImage from '../images/support_system_bg_mafi_technologies.jpg';
import { utils, writeFile } from 'xlsx';
import { trmPost } from "../services/trmService";
/* import { DateFilter } from '@inovua/reactdatagrid-community/DateFilter' */

const gridStyle = {
    height: '100%',
}

const onLoading = () => {
  return (
    <Box className="w-full h-full flex items-center justify-center bg-black bg-opacity-20">
      <CircularProgress color="warning" />
    </Box>
  )
}

const SaltexListTrm = () => {

    const [gridRef, setGridRef] = useState(null);
    const [isEditable, setIsEditable] = useState(true);
    const [openModal, setOpenModal] = useState(false)
    const [mode, setMode] = useState("light");
    const [list, setList] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [itemsData, setItemsData] = useState([]);
    const initialTrm = localStorage.getItem('trm');
    const [selectedTrm, setSelectedTrm] = useState(initialTrm === '3.72' ? 'Peru' : 'Colombia');
    const [trmList, setTrmList] = useState([
      { trm_id: 1, trm_name: 'Colombia', trm_valor: 3990.60 },
      { trm_id: 2, trm_name: 'Peru', trm_valor: 3.72 },
      // Puedes agregar más objetos según sea necesario
    ]);

    useEffect(() => {
      postCliOrder().then((response) => {
        console.log('postCliOrder')
        for(let i=0; i < response.length; i++){
          let vCompra = response[i].compra_valor;
          response[i].compra_valor = parseInt(vCompra).toLocaleString();
        }
        console.log(response)
        setList(response)
        setIsLoading(false)
      })
    }, [])

    const handleTrmChange = (event) => {
      const selectedTrmName = event.target.value;
      const selectedTrmValue = trmList.find((trm) => trm.trm_name === selectedTrmName).trm_valor;
      const selectedTrmId = trmList.find((trm) => trm.trm_name === selectedTrmName).trm_id;

      console.log('value ' + selectedTrmValue)
      console.log('id ' + selectedTrmId)
  
      setSelectedTrm(selectedTrmName);
      localStorage.setItem('trm', selectedTrmValue);
      trmPost(selectedTrmId, selectedTrmValue)
    }

    const darkTheme = createTheme({
      palette:{
        mode: mode
      }
    });

    const columns = [
      { name: 'trm_name', header: 'Pais' },
      { name: 'trm_valor', header: 'Valor' },
      {
        name: 'Edit', header: 'Edit', maxWidth: 200, defaultFlex: 1, render: ({ value, cellProps }) => {
          return (
            <Box className='flex flex-row justify-around'>
              {value}
              <Box>
                <IconButton onClick={() => {
                  gridRef?.current?.startEdit?.({ rowIndex: cellProps.rowIndex ?? 0, columnId: 'trm_valor' });
                }}>
                  <EditIcon sx={{color:'#000'}} />
                </IconButton>
              </Box>
            </Box>
          )
        }
      },
    ];

    const onEditComplete = useCallback(({ value, columnId, rowIndex }) => {
      let newData = [...trmList];
  
      if (value === null) {
        // Elimina la fila si el valor es nulo
        newData = newData.filter((_, index) => index !== rowIndex);
      } else {
        // Actualiza la celda editada
        newData[rowIndex][columnId] = parseFloat(value); // Asegurarse de que el valor sea un número
        setTrmList(newData); // Actualiza el estado trmList
        localStorage.setItem('trm', newData[rowIndex].trm_valor.toString()); // Actualiza el localStorage
      }
  
      // Aquí puedes acceder a toda la data de la fila seleccionada
      const editedRowData = newData[rowIndex];
      console.log(editedRowData);
    }, [trmList, setTrmList]);
      
      const rows = [
        { id: 0, items: 'Example', cantidad: 1, url: 'www.amazon.com', estado: 'Pago' },
      ];

    const handleModal = (boolean) => {
        setOpenModal(boolean);
    };

    const showItemsModal = useCallback(({value, columnId, rowIndex}) => {
      setItemsData(list[rowIndex].items);
      handleModal(true);
    });

    return (
      <ThemeProvider theme={darkTheme}>
      <Box className="h-full">
        <NavBar />
        <Box className="w-full flex h-full">
          <PPNavigation />
        {isLoading ? onLoading() : (
          <Box className="w-full h-full flex flex-col justify-center items-center" sx={{backgroundImage: 'url(' + bgImage + ')', backgroundSize: '100%'}}>
            <Box className="w-2/3 flex justify-center items-center mt-4">
              <Box className="w-1/3 flex flex-col justify-center items-center">
                <Typography fontSize={20} >Configurar TRM</Typography>
                <Select
                  value={selectedTrm}
                  onChange={handleTrmChange}
                  style={{ marginTop: '8px' }}
                >
                  {trmList.map((trm) => (
                    <MenuItem key={trm.trm_name} value={trm.trm_name}>
                      {trm.trm_name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box className="w-2/5 h-full flex justify-center items-center mt-4" >
                <ReactDataGrid
                    className="w-full"
                    idProperty="id"
                    style={gridStyle}
                    columns={columns}
                    dataSource={trmList}
                    showZebraRows={true}
                    showEmptyRows={false}
                    /* defaultFilterValue={filterValue} */
                    sortable
                    pagination
                    editable={isEditable}
                    onReady={setGridRef}
                    onEditComplete={onEditComplete}
                />
            </Box>
        </Box>
        )}
        </Box>
        {/* <AddTooltip /> */}
        <PopUpItems handleOpen={handleModal} open={openModal} data={itemsData}/>
      </Box>
      </ThemeProvider>
    );
}

export default SaltexListTrm;