import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PPMain from "../ppMain/PPMain";
import SaltexDashboard from '../../pages/SaltexDashboard';
import SaltexUsers from '../../pages/SaltexUsers';
import SaltexCart from '../../pages/SaltexCart';
import SaltexListComprasAg from '../../pages/SaltexListComprasAg';
import SaltexAddUser from '../../pages/SaltexAddUser';
import SaltexListComprasCli from '../../pages/SaltexListComprasCli';
import SaltexListComprasAdm from '../../pages/SaltexListComprasAdm';
import SaltexListTrm from '../../pages/SaltexListTrm';
import { useEffect } from 'react';

const router = createBrowserRouter([
    { path: '/', element: <PPMain />},
    { path: '/dashboard', element: <SaltexDashboard />},
    { path: '/cart', element: <SaltexCart />},
    { path: '/users', element: <SaltexUsers />},
    { path: '/list_ag', element: <SaltexListComprasAg />},
    { path: '/list_cli', element: <SaltexListComprasCli />},
    { path: '/list_admin', element: <SaltexListComprasAdm />},
    { path: '/add_user', element: <SaltexAddUser />},
    { path: '/trm', element: <SaltexListTrm />}
    // { path: '/adminPanel', element: <AdminPanel />},
]);

const App = () => {

    return(
        <RouterProvider router={router} />
    );   
}

export default App;