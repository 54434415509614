
import styled from "@emotion/styled";
import React, { useState } from "react";
import { Alert, Backdrop, Box, Button, CircularProgress, FormControl, Grid, Grow, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import bgImage from '../../images/support_system_bg_mafi_technologies.jpg';
import classes from './PPMain.module.css';
import ForgotPasswd from "./ForgotPasswd";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom/dist';
import { useTranslation } from 'react-i18next';
import { loginClient, loginUser,  logout} from '../../services/authService'

const StyledBox = styled("div")({
    height: '100vh',
    display:"flex",
    alignItems:"center",
    backgroundImage:`url(${bgImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    justifyContent:"center"
});

const SaltexLogo = styled('img')(({theme}) => ({
    [theme.breakpoints.up("sm")]:{
        width: '50%',
    },
    [theme.breakpoints.down("sm")]:{
        width: '40%',
    },
    p:20
}));



const languages = [
    { value: '', text: "Options" },
    { value: 'en', text: "English" },
    { value: 'es', text: "Español" }
];

const SSMain = () => {
    logout();
    const [newLoginPopUp, setNewLoginPopUp] = useState(true);
    const [checked, setChecked] = React.useState(true);
    const [message, setMessage] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [lang, setLang] = useState('');
    const [authEmail, setAuthEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const languageHandler = (e) => { 
        setLang(e.target.value);
        let loc = "http://localhost:3000/";
        // let loc = "https://salientdashboardpm.mafi.ai/";
        window.location.replace(loc + "?lng=" + e.target.value);
    }

    const loginPost = async (e) => {
        e.preventDefault()
        let psPasswd = authEmail.toLocaleUpperCase();
        setAuthEmail(psPasswd);
        if (psPasswd.includes('CU') && !psPasswd.includes('@')) {
            try {
                const resAuth = await loginClient(authEmail, password);
            
                if (resAuth.status === 200) {
                  const data = await resAuth.json();
                  // console.log(data[0]);
                  if(data.length !== 0){
                    localStorage.setItem('user_new', JSON.stringify(data[0]))
                    localStorage.setItem('cart', JSON.stringify([]))
                    navigate('/Dashboard')
                } else {
                  setMessage(
                      <Alert severity="error">Usuario y contraseña incorrecto</Alert>
                    );
                    setTimeout(function () {
                      setMessage("");
                    }, 4000);
                }
                }
              } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const resAuth = await loginUser(authEmail, password);
            
                if (resAuth.status === 200) {
                  const data = await resAuth.json();
                  // console.log(data[0]);
                  if(data.length !== 0){
                      localStorage.setItem('user_new', JSON.stringify(data[0]))
                      navigate('/Dashboard')
                  } else {
                    setMessage(
                        <Alert severity="error">Usuario y contraseña incorrecto</Alert>
                      );
                      setTimeout(function () {
                        setMessage("");
                      }, 7000);
                  }
                }
              } catch (error) {
                console.log(error);
            }
        }
    }

    const handleAuthEmail = (event) => {
        setAuthEmail(event.target.value);
    }

    const handlePassword = (event) => {
        setPassword(event.target.value);
    }

    const handleClickShowPassword = () => {
        // console.log(showPassword);
        setShowPassword((showPassword) => !showPassword);
    }

    const showForgotPasswd = () => {
        setNewLoginPopUp(false);
    }

    const loginDivContent = <Box width={500} height={420} bgcolor="white" p={3} borderRadius={5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={open}
                                    >
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                
                                <Box justifyContent={'center'} alignItems={'center'}>
                                    <SaltexLogo src="assets/logos/logo_company_dark.png" alt="logo" />
                                    <Typography className="font-bold mt-32 text-4xl tracking-tight leading-tight" margin={2} left={20}>
                                        {t('Bienvenido a la tienda virtual')}
                                    </Typography>
                                    {message}
                                    <form className={classes.signInForm}>
                                        <FormControl sx={{ m: 1, width: '85%', left:20 }} variant="standard">
                                            <InputLabel htmlFor="authEmail">
                                                Buzon - Nombre de usuario (Agencia)
                                            </InputLabel>
                                            <Input
                                            id="authEmail" 
                                            autoFocus 
                                            type='text' 
                                            required 
                                            value={authEmail} 
                                            onChange={handleAuthEmail}
                                            startAdornment={
                                                <InputAdornment position="start">
                                                <AccountCircle />
                                                </InputAdornment>
                                            }
                                            />
                                        </FormControl>
                                        <FormControl sx={{ m: 1,  width: '85%', left:20 }} variant="standard">
                                        <InputLabel htmlFor="passwordLogin">{t('contraseña')}</InputLabel>
                                        <Input
                                            id="passwordLogin"
                                            type={showPassword ? 'text' : 'password'} 
                                            // error={!!AuthErrors.password}
                                            required 
                                            onChange={handlePassword}
                                            endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }
                                        />
                                        </FormControl>

                                        <FormControl sx={{ width: '85%', left:20, margin: 2 }} variant="standard">
                                            <Button
                                                onClick={(e => {loginPost(e)})}
                                                variant="outlined"
                                                className=" w-full mt-16"
                                                aria-label="Login"
                                                type="submit"
                                                size="large"  
                                            >
                                                {t('Entrar')}
                                            </Button>
                                        </FormControl>
                                    </form>
                                    {/* <Grid container spacing={2} className={classes.signInFooter}>
                                        <Grid item xs={8}>
                                            <FormControl sx={{ m: 1, width: '70%', left:27 }} variant="standard">
                                            <Button
                                                variant="outlined"
                                                className='h50'
                                                aria-label="Login"
                                                type="submit"
                                                size="large"  
                                                onClick={showForgotPasswd}
                                            >
                                                {t('forgot-password')}
                                            </Button>
                                        </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl sx={{ m: -1, width: '100%', left:-30 } } variant="standard">
                                                <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select" 
                                                    className='font-size-12 h50'
                                                    value={lang}
                                                    label='language'
                                                    onChange={languageHandler}
                                                >
                                                    {languages.map(item => {
                                                        return (<MenuItem key={item.value} 
                                                        value={item.value}>{item.text}</MenuItem>);
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid> */}
                                </Box>
                            </Box>;

    return(
        <StyledBox > 
            <Grow 
            in={checked}  
            style={{ transformOrigin: '0 0 0' }}
            {...(checked ? { timeout: 1000 } : {})}>
            {newLoginPopUp ? loginDivContent : <ForgotPasswd />}     
        </Grow>
        </StyledBox>
    );
}

export default SSMain;