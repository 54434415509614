import {
    Box,
    Button,
    Typography,
    ListItem,
    ListItemText,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { postOrder } from "../../services/orderService";
import Swal from 'sweetalert2';

  const onLoading = () => {
    return (
      <ListItem className="w-full justify-center items-center">
        <ListItemText primary='Loading...' />
      </ListItem>
    )
  }

  const ProductDetails = ({ products, quantities, handleDecrease, handleIncrease, handleRemove  }) => {
    return (
      <Box>
        {products.map((product, index) => (
          <Box
            key={index}
            id={`cardDetailsProduct-${index}`}
            className="flex flex-row justify-around items-center h-full w-full border border-gray-300 rounded  pt-4 pb-4 mb-4"
          >
            <Box>
              <img
                src={
                  !product.image
                    ? "Imagen no disponible"
                    : product.image.imageUrl
                    ? product.image.imageUrl
                    : product.image
                }
                alt={product.title}
                className="w-20 h-auto mb-2"
              />
            </Box>
            <Box>
              {/* <Link
                href={product.link}
                target="_blank"
                rel="noopener noreferrer"
              > */}
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "17px",
                    marginBottom: "0.5rem",
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "150px",
                    textDecoration: "underline",
                  }}
                >
                  {product.title}
                </Typography>
              {/* </Link> */}
              <Typography
                sx={{
                  fontSize: "13px",
                  marginBottom: "0.5rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                }}
              >
                Nuevo
              </Typography>
            </Box>
            <Box className="flex flex-col justify-center items-center">
              <Box className="flex flex-row justify-between items-center w-20">
                <Button
                  onClick={() => handleDecrease(index)}
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid black",
                    color: "black",
                    width: "20px",
                    height: "20px",
                    minWidth: "unset",
                    padding: "0",
                  }}
                >
                  -
                </Button>
                <Typography>{quantities[index]}</Typography>
                <Button
                  onClick={() => handleIncrease(index)}
                  sx={{
                    borderRadius: "50%",
                    border: "1px solid black",
                    color: "black",
                    width: "20px",
                    height: "20px",
                    minWidth: "unset",
                    padding: "0",
                  }}
                >
                  +
                </Button>
              </Box>
              <Typography
                sx={{
                  fontWeight: "medium",
                  fontSize: "15px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "200px",
                }}
              >
                US $ {(product.price.value * quantities[index]).toLocaleString()}
              </Typography>
              <Button
                onClick={() => handleRemove(index)}
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #ff0000", // Color rojo
                  color: "#ff0000", // Color rojo
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "#cc0000", // Color rojo más oscuro al pasar el mouse
                    color: "#cc0000", // Color rojo más oscuro al pasar el mouse
                  },
                  width: "16px", // Ancho más pequeño
                  height: "16px", // Altura más pequeña
                  fontSize: "10px",
                }}
              >
                Eliminar
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  const AddressForm = ({ quantities, cartProducts }) => {

    const navigate = useNavigate()
    const [send, setSend] = useState(0);
    const [completarButtonView, setCompletarButtonView] = useState(false);
    const [trm, setTrm] = useState(localStorage.getItem('trm'))
    const valUnderIva = 200 * trm;
  
    // Ensure quantities and cartProducts are defined
    if (!quantities || !cartProducts) {
      // Handle the case where quantities or cartProducts is undefined
      return <div>Error: Quantities or cartProducts is undefined</div>;
    }
  
    // Calcular la cantidad total de productos
    const totalQuantity = quantities.reduce((total, quantity) => total + quantity, 0);
  
    // Calcular el precio total de productos
    const totalPrice = parseInt(cartProducts.reduce(
      (total, product, index) => total + product.price.value * quantities[index],
      0
    ));

    const usaTax = parseInt(totalPrice * 0.07);
    const colIva = parseInt(totalPrice * 0.17);  
    const isIva =  valUnderIva < totalPrice ? true : false; 
  
    // Calcular el subtotal (precio total de productos + envío)
    const subtotal = parseInt(totalPrice + usaTax + colIva);
  
    const sendOrder = () => {
      if(totalPrice>0){
        postOrder(subtotal, quantities, cartProducts).then((response) => {
          console.log(response)
          if (response.status === 200) {
            Swal.fire({
              title: 'Excelente!',
              text: 'Su pedido fue realizado exitosamente.',
              icon: 'success',
              confirmButtonText: 'Ok!'
            })
            localStorage.removeItem('cart');
            navigate('/dashboard');
          } else {
            Swal.fire({
              title: 'Upps!',
              text: 'Su carrito esta vacio.',
              icon: 'error',
              confirmButtonText: 'Ok!'
            })
          }
        })
      } 
    }
    return (
      <Box className="flex flex-col justify-around items-center h-full w-full">
        <Box className="flex flex-col justify-center items-center w-full">
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Articulos ({totalQuantity})
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              US $ {totalPrice.toLocaleString()}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
            Taxes USA 7%:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              US $ {usaTax.toLocaleString()}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
            IVA:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              US $ { isIva ? colIva.toLocaleString() : 0}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-2/3 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Subtotal
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "15px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              US $ {subtotal.toLocaleString()}
            </Typography>
          </Box>
        </Box>
        <Box className="flex justify-center items-center w-2/3 mt-4"> 
            <Button
              onClick={sendOrder}
              sx={{
                backgroundColor: "#f9b22b",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#e09d21",
                },
                width: "220px",
                height: "50px",
              }}
            >
              Completar Compra
            </Button>
        </Box>
        <Box className="flex justify-center items-center w-2/3 mt-4 mb-4">
          <Button
            onClick={() => {
              navigate("/Dashboard")
            }}
            sx={{
              backgroundColor: "#f9b22b",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#e09d21",
              },
              width: "220px",
              height: "50px",
            }}
          >
            Seguir Comprando
          </Button>
        </Box>
      </Box>
    );
  };

  const UserInfo = () => {
    const userData = JSON.parse(localStorage.getItem('user_new'));
    return (
      <Box className="flex flex-col justify-around items-center h-full w-full">
        <Box className="flex flex-col justify-center items-center w-full">
        <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
                marginBottom: "0.5rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
                textDecoration: "underline",
              }}
            >
              Datos del Receptor
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              Nombre: 
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              {userData.cli_nombre}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "250px",
              }}
            >
              Dir. de Entrega:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {userData.cuenta_direccion}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "250px",
              }}
            >
              Ciudad:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {userData.cuenta_cuidad} - {userData.cuenta_pais}
            </Typography>
          </Box>
          <Box className="flex flex-row justify-between items-center w-5/6 mt-4 mb-4">
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "250px",
              }}
            >
              Telefono:
            </Typography>
            <Typography
              sx={{
                fontWeight: "medium",
                fontSize: "12px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "300px",
              }}
            >
              {userData.cli_tel}
            </Typography>
          </Box>
        </Box>
        {/* <Box className="flex justify-center items-center w-2/3 mb-4">
          <Button
            sx={{
              backgroundColor: "#f9b22b",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#e09d21",
              },
              width: "220px",
              height: "50px",
            }}
          >
            Completar Envio
          </Button>
        </Box> */}
      </Box>
    );
  };
  
  const Cart = () => {

    const [cartProducts, setCartProducts] = useState([]);
    const [quantities, setQuantities] = useState(
      cartProducts.map(() => 1)
    );

    useEffect(() => {
      // Recuperar los datos del localStorage
      console.log(localStorage.getItem("cart"))
      const cartData = localStorage.getItem("cart");
      const parsedCartData = cartData ? JSON.parse(cartData) : [];
      setCartProducts(parsedCartData);
      setQuantities(parsedCartData.map(() => 1))
      // console.log('useEffect')
      // console.log(cartProducts)
    }, []);
  
    const handleDecrease = (index) => {
      if (quantities[index] > 1) {
        const newQuantities = [...quantities];
        newQuantities[index] -= 1;
        setQuantities(newQuantities);
      }
    };
  
    const handleIncrease = (index) => {
      if (quantities[index] < 5) {
        const newQuantities = [...quantities];
        newQuantities[index] += 1;
        setQuantities(newQuantities);
      }
    };

    const handleRemove = (index) => {
      const newCartProducts = [...cartProducts];
      newCartProducts.splice(index, 1); // Eliminar el producto en la posición index
      setCartProducts(newCartProducts);
      setQuantities(newCartProducts.map(() => 1));
  
      // Actualizar localStorage
      localStorage.setItem("cart", JSON.stringify(newCartProducts));
    };

    return (
      <Box className="w-full h-full flex flex-col justify-start items-center mt-10" >
        <Typography variant="h4">Carrito de Compras</Typography>
        <Box className="flex flex-col items-start justify-center w-full md:flex-row md:w-full">
          <Box className="w-full m-2 h-auto border border-gray-300 rounded md:w-1/4">
            <UserInfo />
          </Box>
          <Box className="w-full m-2 h-full md:w-2/4 overflow-y-auto max-h-[500px]">
            <ProductDetails
            products={cartProducts}
            quantities={quantities}
            handleDecrease={handleDecrease}
            handleIncrease={handleIncrease}
            handleRemove={handleRemove} />
          </Box>
          <Box className="w-full m-2 h-auto border border-gray-300 rounded md:w-1/4">
            <AddressForm quantities={quantities} cartProducts={cartProducts} />
          </Box>
        </Box>
      </Box>
    );
  };
  
export default Cart;
  