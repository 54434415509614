const API_URL = 'https://ec-backend.vieratech.ai/';

export const loginClient = (buzon, password) => {
  // console.log('loginClient')
  const authData = {
    buzon: buzon,
    password: password,
  }
 
  return new Promise(function (resolve, reject) {
      fetch(API_URL + 'user.php/user/loginUser',{
          method: 'POST',
          body: JSON.stringify(authData),
      }).then((response) => {
        // console.log('response')
        // console.log(response)
        resolve(response);
      })
  });
}

export const loginUser = (username, password) => {
  // console.log('loginUser')
  const authData = {
    username: username,
    password: password,
  }
 
  return new Promise(function (resolve, reject) {
      fetch(API_URL + 'user.php/user/loginAgencia',{
          method: 'POST',
          body: JSON.stringify(authData),
      }).then((response) => {
        // console.log('response')
        // console.log(response)
        resolve(response);
      })
  });
}

export const logout = () => {
  localStorage.removeItem("user_new");
}