const API_URL = 'https://ec-backend.vieratech.ai/';

export const postOrder = (totalPrice, itemsQuantity, itemsProducts) => {
    /* localStorage.setItem('user_new', JSON.stringify(userNew)) */
    const userDataString = localStorage.getItem('user_new');
    
    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cliId = userData.cli_id;
    const cuentaId = userData.cuenta_id;

    const authData = {
        usuario_id: cliId,
        cuenta_id: cuentaId,
        compra_valor: totalPrice,
        compra_items: []
    };
    console.log('authData')
    console.log(authData)

    // Recorrer el array itemsQuantity
    itemsQuantity.forEach((quantity, index) => {
        // Crear un objeto para cada elemento de itemsQuantity
        const compraItem = {
            item_cantidad: quantity.toString(),
            item_url: "",
            item_title: ""
        };

        // Verificar si hay un elemento correspondiente en itemsProducts
        // if (itemsProducts[index] && itemsProducts[index].price && itemsProducts[index].prices[0]) {
        if (itemsProducts[index] && itemsProducts[index].price) {
            // Obtener el enlace del primer precio en itemsProducts
            // compraItem.item_url = itemsProducts[index].prices[0].link;
            compraItem.item_url = itemsProducts[index].link;
            compraItem.item_title = itemsProducts[index].title;
            compraItem.item_price = itemsProducts[index].price.value;
        }

        // Agregar el objeto compraItem al array compra_items
        authData.compra_items.push(compraItem);
    });

    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/addUserShop', {
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            resolve(response);
        });
    });
};

export const postAgOrder = () => {

    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.agencia_id;

    const authData = {
      cuenta_id: cuentaId,
    }

   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/listShoppingByAg',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const postCliOrder = () => {

    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cliId = userData.cli_id;

    const authData = {
        cli_id: cliId,
    }
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/listShoppingByCli',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const postAdmOrder = () => {

    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.cuenta_id;

    const authData = {
      cuenta_id: cuentaId,
    }
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'shopping.php/shopping/listShoppingByAdmin',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const ckeckAgPassword = (password, compra_id) => {
    console.log('ckeckAgPassword')
    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.agencia_id;

    const authData = {
      user_id: cuentaId,
      password: password,
      compra_id: compra_id,
    }

    console.log(authData)
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'user.php/user/ckeckAgPassword',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
}

export const updateToComprada = (password, compra_id) => {
    console.log('updateToComprada')
    const userDataString = localStorage.getItem('user_new');

    if (!userDataString) {
        // Manejar el caso en el que no hay datos de usuario en el almacenamiento local
        console.error('No hay datos de usuario en el almacenamiento local');
        return;
    }

    // Convertir la cadena JSON a un objeto JavaScript
    const userDataArray = JSON.parse(userDataString);

    // userDataArray es un array, supongamos que solo hay un elemento en el array
    const userData = userDataArray;

    // Ahora puedes acceder a cli_id y cuenta_id
    const cuentaId = userData.user_id;

    const authData = {
      user_id: cuentaId,
      compra_id: compra_id,
    }

    console.log(authData)
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'user.php/user/updateToComprada',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
            return response.json();
        })
        .then((jsonData) => {
            console.log(jsonData)
            resolve(jsonData);
        });
    });
}
