const API_URL = 'https://ec-backend.vieratech.ai/';

export const trmPost = (id, value) => {
    console.log('trmPost')
    const authData = {
      trm_id: id,
      trm_new: value,
    }

    console.log(authData)
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'trm.php/trm/updateTRM',{
            method: 'POST',
            body: JSON.stringify(authData),
        }).then((response) => {
          // console.log('response')
          // console.log(response)
          resolve(response);
        })
    });
  }

  export const trmGet = () => {
    console.log('trmGet')
   
    return new Promise(function (resolve, reject) {
        fetch(API_URL + 'trm.php/trm/listTRM',{
            method: 'POST',
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
            resolve(jsonData);
        });
    });
  }